<template>
  <div class="content">
    <div class="container">
      <div class="head">
        <div class="head_item" ref="divOne">
          <div class="head_left">
            <div class="head_title" ref="title1">总内容</div>
            <div class="head_sum" ref="sum1">{{ informationNUm.informationTotal }}</div>
          </div>

          <div class="head_right" ref="right1">
            <div class="head_img sum_distribution" ref="distribution1">
              <img src="../../../../static/images/Group 2727.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title" ref="h2Title1">今日新增</span>
              <span class="head_newAdd" ref="newAdd1">{{
                informationNUm.informationToDayTotal
              }}</span>
              <i class="el-icon-top" ref="top1"></i>
            </div>
          </div>
        </div>
        <div class="head_item" ref="divTwo">
          <div class="head_left">
            <div class="head_title" ref="title2">知识内容</div>
            <div class="head_sum" ref="sum2">{{ informationNUm.knowledgeTotal }}</div>
          </div>

          <div class="head_right" ref="right2">
            <div class="head_img activity_distribution" ref="distribution2">
              <img src="../../../../static/images/Group 2728.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title" ref="h2Title2">今日新增</span>
              <span class="head_newAdd" ref="newAdd2">{{
                informationNUm.knowledgeToDayTotal
              }}</span>
              <i class="el-icon-top" ref="top2"></i>
            </div>
          </div>
        </div>
        <div class="head_item" ref="divThree">
          <div class="head_left">
            <div class="head_title" ref="title3">情报内容</div>
            <div class="head_sum" ref="sum3">{{ informationNUm.intelligenceTotal }}</div>
          </div>

          <div class="head_right" ref="right3">
            <div class="head_img demand_distribution" ref="distribution3">
              <img src="../../../../static/images/Group 2729.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title" ref="h2Title3">今日新增</span>
              <span class="head_newAdd" ref="newAdd3">{{
                informationNUm.intelligenceToDayTotal
              }}</span>
              <i class="el-icon-top" ref="top3"></i>
            </div>
          </div>
        </div>
        <div class="head_item" ref="divFour">
          <div class="head_left">
            <div class="head_title" ref="title4">集市内容</div>
            <div class="head_sum" ref="sum4">{{ informationNUm.marketTotal }}</div>
          </div>

          <div class="head_right" ref="right4">
            <div class="head_img report_distribution" ref="distribution4">
              <img src="../../../../static/images/Group 2730.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title" ref="h2Title4">今日新增</span>
              <span class="head_newAdd" ref="newAdd4">{{ informationNUm.marketToDayTotal }}</span>
              <i class="el-icon-top" ref="top4"></i>
            </div>
          </div>
        </div>
      </div>
      <el-card shadow="never">
        <div class="context">
          <el-form ref="form" :model="queryInfo" label-width="100px">
            <el-form-item style="margin-bottom: 0" label="资讯类型：">
              <el-radio-group v-model="queryInfo.informationType" @change="search">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
          </el-form>
          <!-- 新增--搜索 -->
          <div class="add_search">
            <div class="left">
              <div class="add" v-if="$hasPerms('content:add')" @click="addcontent">新增</div>

              <el-button
                v-if="$hasPerms('content:updowm')"
                style="margin-right: 5px"
                @click="updateOnShelf"
                >批量发布</el-button
              >
              <el-button
                v-if="$hasPerms('content:updowm')"
                style="margin-right: 5px"
                @click="updateOffShelf"
                >批量下架</el-button
              >

              <div class="ckeckbox_text">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </div>
            </div>
            <div class="right">
              <el-input
                v-model="queryInfo.informationName"
                placeholder="请输入内容名称"
                clearable
              ></el-input>
              <div class="add" @click="search">搜索</div>
            </div>
          </div>

          <!-- 表格 -->
        </div>
      </el-card>
      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div style="display: flex; align-items: center; padding-bottom: 10px">
          <div style="padding-right: 50px">
            <span>当前条件下共查出{{ total }}篇资讯</span>
          </div>
          <!-- <div style="display: flex; align-items: center; margin-right: 30px">
          按查看次数
          <div class="main">
            <span class="arrUp" @click="clickUp1" :style="{ 'border-bottom-color': orderinfo.color1 }"></span>
            <span
              class="arrDown"
              @click="clickDown2"
              :style="{ 'border-top-color': orderinfo.color2 }"
            ></span>
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 30px">
          按查看人数
          <div class="main">
            <span class="arrUp" @click="clickUp3" :style="{ 'border-bottom-color': orderinfo.color3 }"></span>
            <span
              class="arrDown"
              @click="clickDown4"
              :style="{ 'border-top-color': orderinfo.color4 }"
            ></span>
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 30px">
          按分享人数
          <div class="main">
            <span class="arrUp" @click="clickUp5" :style="{ 'border-bottom-color': orderinfo.color5 }"></span>
            <span
              class="arrDown"
              @click="clickDown6"
              :style="{ 'border-top-color': orderinfo.color6 }"
            ></span>
          </div>
        </div> -->
          <!-- <div style="display: flex; align-items: center; margin-right: 30px">
          按对接人数
          <div class="main">
            <span class="arrUp" @click="clickUp7" :style="{ 'border-bottom-color': orderinfo.color7 }"></span>
            <span
              class="arrDown"
              @click="clickDown8"
              :style="{ 'border-top-color': orderinfo.color8 }"
            ></span>
          </div>
        </div> -->
        </div>

        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div class="storeCenter_item_top">
            <div class="storeCenter_item_top_left">
              <div class="banner_box">
                <!-- <div class="activity_state" v-if="item.generalVersion == 1">
                <span>个性版</span>
              </div> -->
                <div class="activity_state" v-if="item.generalVersion == 0">
                  <span>通用版</span>
                </div>
                <img :src="item.coverImages" alt="" />
              </div>
              <div class="storeCenter_item_top_left_flex">
                <div style="display: flex; align-items: center">
                  <h5
                    style="
                      display: inline-block;
                      margin: 0;
                      font-size: 18px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      width: 300px;
                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ item.informationName }}
                  </h5>
                </div>
                <div class="address">
                  <span v-if="item.informationType == 1"> 内容类型: 应用场景 </span>
                  <span v-if="item.informationType == 2"> 内容类型: 数智技术 </span>
                  <span v-if="item.informationType == 3"> 内容类型: 行业动态 </span>
                  <span v-if="item.informationType == 4"> 内容类型: 数智案例 </span>
                  <span v-if="item.informationType == 5"> 内容类型: 数智产品 </span>
                  <span v-if="item.informationType == 6"> 内容类型: 服务商 </span>
                  <span v-if="item.informationType == 7"> 内容类型: 政策解读 </span>
                  <span v-if="item.informationType == 8"> 内容类型: 组织人才 </span>
                </div>
                <div class="address" style="display: flex; align-items: center">
                  <span>发布机构：</span>
                  <img
                    width="15"
                    height="15"
                    style="border-radius: 15px"
                    :src="item.issueLogo"
                    alt=""
                  />
                  <span style="margin-left: 5px"> {{ item.issueName }} </span>
                </div>
              </div>
            </div>
            <div class="storeCenter_item_top_center">
              <!-- <div class="count_box" @click="topush(item)">
              <div>{{ item.checkCount }}</div>
              <div>已推送</div>
            </div> -->
              <!-- <div v-if="$hasPerms('content:checkuser')" class="count_box" @click="listUserCheckPage(item)">
              <div>{{ item.checkCount }}</div>
              <div>查看</div>
            </div> -->
              <!-- <div class="count_box" @click="listUserSharePage(item)">
              <div>{{ item.shareCount }}</div>
              <div>分享</div>
            </div> -->
              <!-- <div class="count_box">
              <div>{{ item.abutmentCount||0  }}</div>
              <div>对接</div>
            </div> -->
            </div>

            <div class="storeCenter_item_top_right">
              <div class="operation_area">
                <div v-if="$hasPerms('content:updowm')">
                  <span
                    v-if="item.isShelf == 1 || item.isShelf == null"
                    class="release"
                    @click="OnShelf(item)"
                    >发布</span
                  >
                  <span v-if="item.isShelf == 0" class="shelf" @click="OffShelf(item)">下架</span>
                </div>

                <div v-if="$hasPerms('content:edit')">
                  <span
                    v-if="item.informationChannel == 2"
                    class="office"
                    @click="editcontent(item)"
                    >编辑</span
                  >
                </div>
                <div v-if="$hasPerms('content:freeze')">
                  <span v-if="item.isDelete == 0" class="support" @click="delcompany(item)"
                    >冻结</span
                  >
                  <span v-if="item.isDelete == 1" class="support" @click="cancelcompany(item)"
                    >激活</span
                  >
                </div>
              </div>
              <div class="details">操作人：{{ item.userName }}</div>
              <div class="details">最近操作时间：{{ item.updateTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
const defaultQueryInfo = Object.freeze({
  partnerChannelId: '', //合作伙伴编号
  schemaName: '', //智参中心schema值
  informationName: '', //资讯名称
  informationType: '', //1应用场景，2数智技术，3行业动态，4数智案例，5数智产品，6服务商,7政策解读,8组织人才
  orders: '1', //排序 默认传1 2->查看次数升序;3->查看次数降序;4->查看人数升序;5->查看人数降序;6->分享人数升序;7->分享人数降序
  pageNum: 1,
  pageSize: 10
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6',
  color3: '',
  color4: '#C6C6C6',
  color5: '',
  color6: '#C6C6C6',
  color7: '',
  color8: '#C6C6C6'
})
import {
  informationNum,
  informationPage,
  informationFreeze,
  informationThawing,
  updateOffShelf,
  updateOnShelf
} from '../../../api/content'
export default {
  data() {
    //这里存放数据
    return {
      // 遮罩层
      loading: true,
      total: 0, //咨询条
      checkAll: false,
      isIndeterminate: false,
      parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),
      InformationTypes: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '应用场景',
          id: 1
        },
        {
          value: '数智技术',
          id: 2
        },

        {
          value: '行业动态',
          id: 3
        },
        {
          value: '数智案例',
          id: 4
        },

        {
          value: '数智产品',
          id: 5
        },
        {
          value: '数智服务商',
          id: 6
        },
        {
          value: '政策解读',
          id: 7
        },
        {
          value: '组织人才',
          id: 8
        }
      ],

      // 只看服务商上传的咨询 的多选框
      checked: false,
      // 表格数据
      listData: [],
      // 资讯类名控制
      typesClass: 999,
      // 接口得到的资讯条数数据
      informationNUm: {},
      //  表格需要的数据
      queryInfo: { ...defaultQueryInfo },
      //排序字段
      orderinfo: { ...defaultOrder },
      // 移除内容池
      poolShow: false,
      //
      imageShow: -1,
      enterpriseIntroShow: -1,
      // 移除内容池所需要的id
      PoolId: null,
      // 移除成功的字段
      removeSucceed: 0,
      aaa: 0,
      ids: [],
      idsList: [],
      idof: [] //判断勾选
    }
  },
  created() {
    this.getInformationNUm()
    this.search()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize() // 初始化时调用一次
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.schemaName)
    },
    // 资讯类型的点击方法
    clickType(item) {
      this.typesClass = item.id
      if (item.id == 999) {
        this.query.informationType = null
        this.search()
      } else {
        this.query.informationType = item.id
        this.search()
      }

      // console.log(item.value)
    },
    // 多选框选中数据

    latechange(bol, row) {
      const index = this.idof.indexOf(row.foreignKey)
      if (index == -1) {
        this.ids.push({
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        })
        this.idof.push(row.foreignKey)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idof.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      console.log(this.ids)
    },
    //排序按查看次数
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '3'
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    //排序按查看人数
    clickUp3() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '4'
      this.search()
      this.orderinfo.color3 = '#0099ff'
      this.orderinfo.color4 = ''
    },
    clickDown4() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '5'
      this.search()
      this.orderinfo.color3 = ''
      this.orderinfo.color4 = '#0099ff'
    },
    //排序按分享人数
    clickUp5() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '6'
      this.search()

      this.orderinfo.color5 = '#0099ff'
      this.orderinfo.color6 = ''
    },
    clickDown6() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '7'
      this.search()

      this.orderinfo.color5 = ''
      this.orderinfo.color6 = '#0099ff'
    },
    //排序按对接人数
    clickUp7() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '8'
      this.search()

      this.orderinfo.color7 = '#0099ff'
      this.orderinfo.color8 = ''
    },
    clickDown8() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '9'
      this.search()

      this.orderinfo.color7 = ''
      this.orderinfo.color8 = '#0099ff'
    },

    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的资讯中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量下架资讯？')
          .then(function () {
            return updateOffShelf(that.ids)
          })
          .then((res) => {
            if (res.data.resultCode == 200) {
              this.search()
              this.ids = []
              this.isIndeterminate = false
              this.checkAll = false

              if (res.data.data) {
                this.$message.warning(res.data.data)
              } else {
                this.$message.success('下架成功')
              }
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    //批量上架
    updateOnShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的资讯中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量上架资讯？')
          .then(function () {
            return updateOnShelf(that.ids)
          })
          .then((res) => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            if (res.data.data) {
              this.$message.warning(res.data.data)
            } else {
              this.$message.success('上架成功')
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    //冻结资讯
    delcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认冻结？')
        .then(function () {
          return informationFreeze(params)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活资讯
    cancelcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认激活？')
        .then(function () {
          return informationThawing(params)
        })
        .then((res) => {
          if (res.data.resultCode == 200) {
            this.search()
            this.$message.success('激活成功')
          } else {
            this.$message.warning(res.data.message)
          }
        })
        .catch(() => {})
    },
    //单个下架
    OffShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该资讯已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]

      this.$alert('是否确认下架资讯？')
        .then(function () {
          return updateOffShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data.data) {
            this.$message.warning(res.data.data)
          } else {
            this.$message.success('下架成功')
          }
        })
        .catch(() => {})
    },
    //单个上架
    OnShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该资讯已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]

      this.$alert('是否确认上架资讯？')
        .then(function () {
          return updateOnShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data.data) {
            this.$message.warning(res.data.data)
          } else {
            this.$message.success('上架成功')
          }
        })
        .catch(() => {})
    },

    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push({
            foreignKey: item.foreignKey,
            schemaName: this.parteninfo.schemaName
          })
          this.idof.push(item.foreignKey)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.idof = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },

    // 头部资讯条数 数据
    async getInformationNUm() {
      const query = {
        partnerChannelId: this.parteninfo.id,
        schemaName: this.parteninfo.schemaName
      }
      const { data: res } = await informationNum(query)
      // console.log(res)
      if (res.resultCode == 200) {
        this.informationNUm = res.data
      }
    },
    // 表格数据
    async search() {
      this.loading = true
      this.queryInfo.partnerChannelId = this.parteninfo.id
      this.queryInfo.schemaName = this.parteninfo.schemaName

      const { data: res } = await informationPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        this.total = res.data.total
        this.removeSucceed = 1
        this.loading = false
      }
    },
    //新增资讯
    addcontent() {
      this.$router.push({
        name: 'addcontent'
      })
    },
    //编辑资讯
    editcontent(item) {
      this.$router.push({
        name: 'addcontent',
        query: { id: item.id }
      })
    },

    //  查看人数的跳转
    listUserCheckPage(item) {
      this.$router.push({
        name: 'listUserCheckPage',
        query: { id: item.id }
      })
    },

    //  分享人数的跳转
    listUserSharePage(item) {
      this.$router.push({
        name: 'listUserSharePage',
        query: { id: item.id }
      })
    },

    //  收藏人数的跳转
    clickCollectNum(row) {
      // this.$router.push({
      //   path: '/content/collect',
      //   query: {
      //     row: JSON.stringify(row),
      //     activeName: '3'
      //   }
      // })
    },
    // 想联系人数的跳转
    clickLinkNumNum(row) {
      // this.$router.push({
      //   path: '/content/relation',
      //   query: {
      //     row: JSON.stringify(row),
      //     activeName: '4'
      //   }
      // })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
    },
    // 屏幕适配
    handleResize() {
      // 这里可以获取当前的视口大小，并根据需要调整元素的宽度
      const viewportWidth = window.innerWidth
      console.log(viewportWidth)
      // 假设我们想要在某个视口宽度下改变div的宽度
      if (viewportWidth < 1920) {
        if (viewportWidth > 1280) {
          // 总内容
          this.$refs.divOne.style.width = '280px'
          this.$refs.divTwo.style.width = '280px'
          this.$refs.divThree.style.width = '280px'
          this.$refs.divFour.style.width = '280px'
          // 标题字体
          this.$refs.title1.style.fontSize = '24px'
          this.$refs.title2.style.fontSize = '24px'
          this.$refs.title3.style.fontSize = '24px'
          this.$refs.title4.style.fontSize = '24px'
          // sum字体
          this.$refs.sum1.style.fontSize = '41px'
          this.$refs.sum2.style.fontSize = '41px'
          this.$refs.sum3.style.fontSize = '41px'
          this.$refs.sum4.style.fontSize = '41px'
          // 右边
          this.$refs.right1.style.width = '200px'
          this.$refs.right2.style.width = '200px'
          this.$refs.right3.style.width = '200px'
          this.$refs.right4.style.width = '200px'
          // 图片
          this.$refs.distribution1.style.width = '60px'
          this.$refs.distribution1.style.height = '60px'

          this.$refs.distribution2.style.width = '60px'
          this.$refs.distribution2.style.height = '60px'

          this.$refs.distribution3.style.width = '60px'
          this.$refs.distribution3.style.height = '60px'

          this.$refs.distribution4.style.width = '60px'
          this.$refs.distribution4.style.height = '60px'
          //
          this.$refs.h2Title1.style.fontSize = '14px'
          this.$refs.newAdd1.style.fontSize = '20px'
          this.$refs.top1.style.fontSize = '20px'

          this.$refs.h2Title2.style.fontSize = '14px'
          this.$refs.newAdd2.style.fontSize = '20px'
          this.$refs.top2.style.fontSize = '20px'

          this.$refs.h2Title3.style.fontSize = '14px'
          this.$refs.newAdd3.style.fontSize = '20px'
          this.$refs.top3.style.fontSize = '20px'

          this.$refs.h2Title4.style.fontSize = '14px'
          this.$refs.newAdd4.style.fontSize = '20px'
          this.$refs.top4.style.fontSize = '20px'
        } else {
          // 总内容
          this.$refs.divOne.style.width = '245px'
          this.$refs.divTwo.style.width = '245px'
          this.$refs.divThree.style.width = '245px'
          this.$refs.divFour.style.width = '245px'
          // 标题字体
          this.$refs.title1.style.fontSize = '18px'
          this.$refs.title2.style.fontSize = '18px'
          this.$refs.title3.style.fontSize = '18px'
          this.$refs.title4.style.fontSize = '18px'
          // sum字体
          this.$refs.sum1.style.fontSize = '18px'
          this.$refs.sum2.style.fontSize = '18px'
          this.$refs.sum3.style.fontSize = '18px'
          this.$refs.sum4.style.fontSize = '18px'
          // 右边
          this.$refs.right1.style.width = '200px'
          this.$refs.right2.style.width = '200px'
          this.$refs.right3.style.width = '200px'
          this.$refs.right4.style.width = '200px'
          // 图片
          this.$refs.distribution1.style.width = '60px'
          this.$refs.distribution1.style.height = '60px'

          this.$refs.distribution2.style.width = '60px'
          this.$refs.distribution2.style.height = '60px'

          this.$refs.distribution3.style.width = '60px'
          this.$refs.distribution3.style.height = '60px'

          this.$refs.distribution4.style.width = '60px'
          this.$refs.distribution4.style.height = '60px'
          //
          this.$refs.h2Title1.style.fontSize = '10px'
          this.$refs.newAdd1.style.fontSize = '16px'
          this.$refs.top1.style.fontSize = '16px'

          this.$refs.h2Title2.style.fontSize = '10px'
          this.$refs.newAdd2.style.fontSize = '16px'
          this.$refs.top2.style.fontSize = '16px'

          this.$refs.h2Title3.style.fontSize = '10px'
          this.$refs.newAdd3.style.fontSize = '16px'
          this.$refs.top3.style.fontSize = '16px'

          this.$refs.h2Title4.style.fontSize = '10px'
          this.$refs.newAdd4.style.fontSize = '16px'
          this.$refs.top4.style.fontSize = '16px'
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.main {
  display: flex;
  flex-direction: column;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}

.content {
  width: 100%;
  overflow: auto;
  .container {
    height: 80vh;
    overflow: auto;
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin: 20px 0;
  }
  .head {
    width: 100%;
    height: 165px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .head_item {
      width: 400px;
      height: 165px;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      .head_left {
        width: 50%;
        height: 100%;
        color: #333;
        margin-left: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .head_title {
          font-size: 32px;
          color: #333;
        }
        .head_sum {
          font-size: 26px;
          color: #333;
        }
      }

      .head_right {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        padding-right: 42px;
        .head_img {
          width: 89px;
          height: 89px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .head_sum {
          // display: flex;

          .head_h2Title {
            font-size: 14px;
            color: #ff6c66;
          }
          .head_newAdd {
            margin: 0 6px;
            font-size: 26px;
            color: #ff6c66;
          }
        }
      }
      .head_right i {
        font-size: 21px;
        font-weight: 700;
        color: #ff6c66;
      }
    }
  }

  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 4px;
    border-radius: 4px;
  }

  .context {
    // width: 100%;
    // overflow: auto;
    .types {
      height: 80px;
      border-bottom: 1px double #f3f3f3;
      display: flex;
      align-items: center;

      .title {
        margin-right: 5px;
        color: #7c7f8e;
        font-size: 14px;
      }

      /deep/.el-button {
        border: none;
        font-size: 14px;
        color: #333333;
      }
      /deep/.el-button:hover {
        border-radius: 4px;
        background-color: rgba(78, 147, 251, 0.2);
        color: #4e93fb;
        font-size: 14px;
        cursor: pointer;
      }

      .click_item {
        border-radius: 4px;
        background-color: #4e93fb !important;
        color: #fff !important;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .add_search {
      height: 64px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      .left {
        display: flex;
        align-items: flex-end;
        .add {
          width: 85px;
          height: 40px;
          border-radius: 4px;
          cursor: pointer;
          background-color: #448aff;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
        }
        .ckeckbox_text {
          /deep/.el-checkbox__inner {
            width: 18px;
            height: 18px;
          }
          /deep/.el-checkbox__label {
            color: #333;
          }
          /deep/.el-checkbox__inner::after {
            height: 10px;
            left: 6px;
          }
        }
      }

      .right {
        display: flex;
        align-items: flex-end;
        .add {
          transform: translateX(-2px);
          width: 74px;
          height: 40px;
          cursor: pointer;
          background-color: #448aff;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 33px;
        }
        /deep/.el-input {
          width: 429px;
        }
      }
    }
    .table {
      width: 100%;
      .operate {
        width: 240px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .redact {
          color: #4e93fb;
          font-size: 14px;
          cursor: pointer;
        }
        .del {
          color: #fd5469;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .matching {
        width: 80px;
        height: 33px;
        background-color: #e4e4e4;
        border-radius: 2px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .label {
        width: 180px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: relative;

        .items {
          display: inline-block;
          margin-right: 10px;
          color: #4e93fb;
        }
      }
      .suspend {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        transform: translateX(50%);
        width: 204px;
        // max-width: 204px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 1000;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        box-shadow: 0px 5px 5px rgba(143, 138, 138, 0.25);
        display: flex;
        flex-wrap: wrap;
        .items {
          // display: flex;
          margin-right: 10px;
          display: inline-block;
          color: #4e93fb;
        }
      }
      // /deep/.el-table__body-wrapper {
      //   overflow: visible;
      // }
      // /deep/.el-table {
      //   overflow: visible;
      // }

      .Nums {
        font-size: 14px;
        color: #4e93fb;
        text-decoration: underline;
        cursor: pointer;
      }
      /deep/.cell {
        overflow: visible;
      }
      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table__header-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }

      .img_video {
        width: 69px;
        height: 48px;
        // cursor: pointer;
        position: relative;
        z-index: 999;
        img {
          width: 100%;
          height: 100%;
        }
        .magnify {
          position: absolute;
          top: 40px;
          right: -134px;
          width: 132px;
          height: 92px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .information_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .currency {
        width: 64px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        background: #448aff;
        border-radius: 2px;
        color: #fff;
        margin-top: 10px;
      }

      .pagination {
        width: 100%;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
}
.add_search {
  height: 64px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  .left {
    display: flex;
    align-items: flex-end;
    .add {
      width: 85px;
      height: 40px;
      border-radius: 4px;
      cursor: pointer;
      background-color: #448aff;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 13px;
    }
    .ckeckbox_text {
      /deep/.el-checkbox__inner {
        width: 18px;
        height: 18px;
      }
      /deep/.el-checkbox__label {
        color: #333;
      }
      /deep/.el-checkbox__inner::after {
        height: 10px;
        left: 6px;
      }
    }
  }

  .right {
    display: flex;
    align-items: flex-end;
    .add {
      transform: translateX(-2px);
      width: 74px;
      height: 40px;
      cursor: pointer;
      background-color: #448aff;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 33px;
    }
    /deep/.el-input {
      width: 429px;
    }
  }
}
.tab_txt {
  background: #f6f7fb;

  margin-top: 16px;
  padding: 0px;
  padding-bottom: 20px;
}

.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.storeCenter_item {
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  .item_checkbox {
    line-height: 36px;
    margin: 0px 10px;
  }
  .Relevancy {
    position: absolute;
    width: 83px;
    height: 25px;
    top: 15px;
    left: 9px;
    background: #ff7d18;
    border-radius: 10px 15px 15px 0px;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    color: #ffffff;
  }
  .Relevancyblue {
    position: absolute;
    width: 83px;
    height: 25px;
    top: 15px;
    left: 9px;
    background: #146aff;
    border-radius: 10px 15px 15px 0px;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    color: #ffffff;
  }
  .storeCenter_item_top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 14px;
    position: relative;
    background: #ffffff;
    padding: 16px 20px 10px 10px;
    border-radius: 8px;
    .storeCenter_item_top_left {
      display: flex;
      flex-direction: row;
      width: 480px;
      height: 120.73px;

      .banner_box {
        position: relative;
        width: 175.55px;
        height: 115.73px;
        padding-right: 10px;
        img {
          width: 170.55px;
          height: 117.73px;
          border-radius: 4px;
          margin-right: 18px;
        }

        .activity_state {
          position: absolute;
          width: 51px;
          height: 23px;
          background: #448aff;
          border-radius: 2px;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 23px;
          top: 5px;
          left: 5px;
        }
      }

      .storeCenter_item_top_left_flex {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .generalVersion {
          background: #448aff;
          border-radius: 2px;
          padding: 5px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          /* identical to box height */
          margin: 0 10px;
          color: #ffffff;
        }
        .mark_box {
          display: flex;
          img {
            width: 14px;
            // height: 14px;
            margin-right: 6px;
            color: #6e6e6e;
          }
          & > div {
            margin-left: 10px;
          }
        }
        .img_commer {
          display: flex;
          border: 1px solid #146aff;
          border-radius: 39px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #146aff;
          }
          .color_img {
            background: #146aff;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }
        .img_commers {
          display: flex;
          border: 1px solid #ff9c00;
          border-radius: 39px;
          margin: 0px 10px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #ff9c00;
          }
          .color_img {
            background: #ff9c00;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }

        h4 {
          color: #1f2d3d;
          font-size: 26px;
          font-weight: 500;
          margin: 0;
        }

        .storeCenter_item_top_left_flex_tag {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;

          margin-bottom: 1px;
          width: 500px;
          span {
            box-sizing: border-box;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
          }

          .office {
            border: 1px solid #559eff;
            color: #4e93fb;
            padding: 1px 6px;
            margin-right: 10px;
          }

          .support {
            border: 1px solid #fd523f;
            color: #fd523f;
            margin-left: 12px;
          }
        }

        .address {
          font-size: 16px;
        }
      }
    }
    .storeCenter_item_top_center {
      display: flex;
      width: 30%;
      .count_box {
        height: 80px;
        width: 120px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        cursor: pointer;
        // &:nth-of-type(1) {
        //   border-right: 1px solid #e8e8e8;
        // }
        & > div:nth-of-type(1) {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;

          color: #078bff;
        }
        & > div:nth-of-type(2) {
          margin-top: 20px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
        }
      }
    }
    .storeCenter_item_top_right {
      align-items: stretch;
      height: 100%;
      position: relative;
      .operation_area {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 200px;

        .buttom_right {
          height: 36px;
          line-height: 1px;
        }
        .release {
          display: inline-block;
          width: 89px;
          height: 36px;
          background: #4e93fb;
          border: 1px solid #4e93fb;
          border-radius: 2px;
          color: #ffffff;
          cursor: pointer;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
        }
        .shelf {
          display: inline-block;
          width: 89px;
          height: 36px;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
          background: #ff7d18;
          /* chengs */

          border: 1px solid #ff7d18;
          border-radius: 2px;
          cursor: pointer;
          color: #ffffff;
        }
      }
      .details {
        text-align: right;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #999999;
        margin-top: 5px;
      }
    }
  }
}
.office {
  color: #4e93fb;

  cursor: pointer;
}

.support {
  cursor: pointer;
  color: #fd523f;
}

::v-deep .disinput .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 382px;
}
::v-deep .disinput .el-input-group {
  line-height: normal;
  display: inline-table;
  width: 381px;
  border-collapse: separate;
  border-spacing: 0;
}
/deep/ .el-form-item__label {
  // width: 0px !important;
  text-align: left;
  width: 82px !important;
}
/deep/ .el-form-item__content {
  margin-left: 80px !important;
}
/deep/ .el-card__body{
  padding: 12px 16px 12px 16px;
}
</style>
